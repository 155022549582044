import styled from "styled-components";
import { css } from "styled-components";

export const InfoRow = styled.div<{ compact?: boolean; interactive?: boolean; borderColor?: any; backgroundColor?: any }>`
	background-color: #f7f7f7;
	box-sizing: border-box;
	border-radius: 5px;
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	${(topP) =>
		(topP.compact &&
			css`
				padding: 7px 15px 0px;
			`) ||
		css`
			padding: 20px 20px 10px;
		`}
	${(topP) =>
		(topP.interactive &&
			css`
				&:hover {
					border-color: #0079ad;
					cursor: pointer;

					background-color: #0079ad;
					color: white;
					transition: all 0.5s;
				}
			`) ||
		css`
			// @ts-ignore
			border-top: solid 3px ${() => topP.borderColor || "rgba(0, 0, 0, 0.1)"};
			background-color: ${() => topP.backgroundColor};
		`}
`;

export const InfoColumn = styled.div<{ width?: string }>`
	flex: 0 0 ${(p) => p.width || "auto"};
	${(p) => (p.width ? "" : "margin-right: 40px;")}
	margin-bottom: 10px;
`;

export const InfoColumnBold = styled(InfoColumn)`
	font-weight: bold;
`;

export const InfoColumnValue = styled.div``;
