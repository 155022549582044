import {Link, useNavigate} from "react-router-dom";

import {faPlusCircle} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import {Direction, Flex, Justify} from "components/atoms/Flex";
import {InfoColumn, InfoRow} from "components/atoms/InfoRow";
import TrafficLightBadge from "components/molecules/TrafficLightBadge";
import {MainLayoutPageHeader} from "components/organisms/MainLayout";
import {ErrorWrapper} from "components/organisms/form/BaseField";
import useDateTime from "hooks/useDateTime";
import {InfoColumnValue} from "pages/Users/EditUserPage";

import {useContentQuery, useContentTextQuery} from "./ContentPage.graphql-gen";

export default function ContentPage() {
	const navigate = useNavigate();
	const date = useDateTime();
	const { data, error } = useContentQuery();
	const {data:contentTextData, error: contentTextError} = useContentTextQuery();

	return (
		<Flex justifyContent={Justify.spaceBetween}>
			<div style={{width: "59%"}}>
				<MainLayoutPageHeader
					title={
						<Flex gap="var(--grid-2)">
							Innhold
							<Link to="create" title="Nytt innhold">
								<FontAwesomeIcon icon={faPlusCircle} />
							</Link>
						</Flex>
					}
				/>
				<Flex style={{ marginTop: "40px" }} direction={Direction.column} gap="var(--grid-2)">
					{error && <ErrorWrapper>{error.message}</ErrorWrapper>}
					{data?.paginateContent?.items
						?.slice()
						.sort((a, b) => {
							if (!a || !b || a.publishDate === b.publishDate) return 0;
							return Date.parse(a.publishDate as string) < Date.parse(b.publishDate as string) ? 1 : -1;
						})
						.map((content) => (
							<InfoRow
								key={content.id}
								interactive
								onClick={() => {
									navigate("/content/" + content.id);
								}}
							>
								<InfoColumn width="10em">
									<InfoColumnValue>
										<TrafficLightBadge green={content?.published} red={!content?.published}>
											<>{content?.published ? "Publisert" : "Ikke publisert"}</>
										</TrafficLightBadge>
									</InfoColumnValue>
								</InfoColumn>
								<InfoColumn>
									<InfoColumnValue>
										<b>{content?.title}</b> sett av <b>{content?.uniqueImpressions || 0}</b> sist oppdatert{" "}
										<b>{date.format(content?.publishDate)}</b>
									</InfoColumnValue>
								</InfoColumn>
							</InfoRow>
						))}
				</Flex>
			</div>
			<div style={{width: "39%"}}>
				<MainLayoutPageHeader
					title={
						<Flex gap="var(--grid-2)">
							Innhold Tekst
							<Link to="text/create" title="Ny tekst">
								<FontAwesomeIcon icon={faPlusCircle} />
							</Link>
						</Flex>
					}
				/>
				<Flex style={{ marginTop: "40px" }} direction={Direction.column} gap="var(--grid-2)">
					{error && <ErrorWrapper>{contentTextError?.message}</ErrorWrapper>}
					{contentTextData?.allContentTexts
						?.map((content) => (
							<InfoRow
								key={content.id}
								interactive
								onClick={() => {
									navigate("/content/text/" + content.id);
								}}
							>
								<InfoColumn width="7em">
									<InfoColumnValue>
										<TrafficLightBadge green={content?.published} red={!content?.published}>
											<>{content?.published ? "Publisert" : "Ikke publisert"}</>
										</TrafficLightBadge>
									</InfoColumnValue>
								</InfoColumn>
								<InfoColumn>
									<InfoColumnValue>
										<b>{content?.title}</b> sist oppdatert{" "}
										<b>{date.format(content?.lastUpdated)}</b>
									</InfoColumnValue>
								</InfoColumn>
							</InfoRow>
						))}
				</Flex>
			</div>
		</Flex>
	);
}
