import { useNavigate } from "react-router-dom";

import { Direction, Flex } from "components/atoms/Flex";
import { InfoRow, InfoColumn } from "components/atoms/InfoRow";
import { MainLayoutPageHeader } from "components/organisms/MainLayout";
import { ErrorWrapper } from "components/organisms/form/BaseField";
import { InfoColumnLabel, InfoColumnValue } from "pages/Users/EditUserPage";
import { spotpriceAreaDisplayName } from "utils/SpotpriceArea";

import { useGetSpotpriceAreasQuery } from "./SpotpriceAreasPage.graphql-gen";

export default function SpotpriceAreasPage() {
	const navigate = useNavigate();
	const { data, error } = useGetSpotpriceAreasQuery();

	return (
		<Flex direction={Direction.column} gap="calc( var(--grid-2) * 2)">
			<MainLayoutPageHeader title="Spotprisområder" />
			<Flex direction={Direction.column} gap="var(--grid-2)">
				{error && <ErrorWrapper>{error.message}</ErrorWrapper>}
				{data?.spotpriceZones?.map((spotpriceArea) => (
					<InfoRow
						key={spotpriceArea.id}
						interactive
						onClick={() => {
							navigate("/spotprice-areas/" + spotpriceArea.id);
						}}
					>
						<InfoColumn style={{ minWidth: 200 }}>
							<InfoColumnLabel>
								{spotpriceAreaDisplayName(spotpriceArea?.id)} ({spotpriceArea?.id})
							</InfoColumnLabel>
						</InfoColumn>
						<InfoColumn style={{ minWidth: 400 }}>
							<InfoColumnValue>Spotprisvarsel om pris overstiger {spotpriceArea?.notifyOver} øre</InfoColumnValue>
						</InfoColumn>
						<InfoColumn>
							<InfoColumnValue>
								Prisskala: {spotpriceArea?.lowPrice} - {spotpriceArea?.highPrice} øre
							</InfoColumnValue>
						</InfoColumn>
					</InfoRow>
				))}
			</Flex>
		</Flex>
	);
}
