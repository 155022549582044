import { Link, useNavigate } from "react-router-dom";

import { faPlusCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Direction, Flex } from "components/atoms/Flex";
import { InfoRow, InfoColumn } from "components/atoms/InfoRow";
import { MainLayoutPageHeader } from "components/organisms/MainLayout";
import { ErrorWrapper } from "components/organisms/form/BaseField";
import { InfoColumnValue } from "pages/Users/EditUserPage";

import { useGetFeaturesFlagsQuery } from "./FeatureFlagsPage.graphql-gen";

export default function FeatureFlagsPage() {
	const navigate = useNavigate();
	const { data, error } = useGetFeaturesFlagsQuery();

	return (
		<div>
			<MainLayoutPageHeader
				title={
					<Flex gap="var(--grid-2)">
						Feature flags
						<Link to="create" title="Ny feature">
							<FontAwesomeIcon icon={faPlusCircle} />
						</Link>
					</Flex>
				}
			/>
			<Flex style={{ marginTop: "40px" }} direction={Direction.column} gap="var(--grid-2)">
				{error && <ErrorWrapper>{error.message}</ErrorWrapper>}
				{data?.featureFlags?.map((featureFlag) => (
					<InfoRow
						key={featureFlag.id}
						interactive
						onClick={() => {
							navigate("/features/" + featureFlag.id + "/edit");
						}}
					>
						<InfoColumn width="25%">
							<InfoColumnValue>
								<b>{featureFlag?.displayName}</b> ({featureFlag?.usersCount} brukere)
							</InfoColumnValue>
						</InfoColumn>
					</InfoRow>
				))}
			</Flex>
		</div>
	);
}
