import React from "react";
import { Flex, Justify, Wrap } from "components/atoms/Flex";
import styled from "styled-components";

type WithActive = {
	active?: boolean;
};

const PaginationButton = styled.button<WithActive>``;

interface PaginationProps {
	total: number;
	perPage: number;
	currentPage: number;
	onChange: (newPage: number) => void;
	maxNumberToShow?: number;
	padding?: number;
}

function generateNumberRange(from: number, to: number) {
	const length = to - from;

	return Array.from(Array(length)).map((value, i) => i + from);
}

export default function Pagination(props: PaginationProps) {
	const pages = Math.max(Math.floor(props.total / props.perPage), 1);
	const maxNumberToShow = props.maxNumberToShow || 8;
	const padding = props.padding || 3;

	let fromTo: [number, number] = [0, pages];

	if (pages > maxNumberToShow) {
		fromTo = [
			Math.max(0, props.currentPage - padding),
			Math.min(props.currentPage + padding, pages),
		];
	}

	return (
		<Flex gap="10px" wrap={Wrap.wrap} justifyContent={Justify.center}>
			<PaginationButton
				disabled={props.currentPage <= 0}
				onClick={() => {
					props.onChange(props.currentPage - 1);
				}}
			>
				{"<"}
			</PaginationButton>
			{generateNumberRange(...fromTo).map((i) => (
				<PaginationButton
					disabled={props.currentPage === i}
					active={props.currentPage === i}
					key={i}
					type="button"
					onClick={() => props.onChange(i)}
				>
					{i + 1}
				</PaginationButton>
			))}
			<PaginationButton
				disabled={props.currentPage + 1 >= pages}
				onClick={() => {
					props.onChange(props.currentPage + 1);
				}}
			>
				{">"}
			</PaginationButton>
		</Flex>
	);
}
