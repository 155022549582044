import React from "react";
import styled from "styled-components";

const FooterWrapper = styled.div`
	font-size: 18px;
	line-height: 1.6;
	padding: 0 20px;
	box-sizing: border-box;
`;

const ContentWrapper = styled.div`
	padding-bottom: 24px;
	font-size: 15px;
	text-align: center;

	p:last-of-type {
		margin-bottom: 0;
	}
`;

export default function Footer() {
	return (
		<FooterWrapper>
			<ContentWrapper>
				<p>© Nord-Trøndelag Elektrisitetsverk AS {new Date().getFullYear()}</p>
			</ContentWrapper>
		</FooterWrapper>
	);
}
