import { Link, useNavigate } from "react-router-dom";

import { faPlusCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Direction, Flex } from "components/atoms/Flex";
import { InfoRow, InfoColumn } from "components/atoms/InfoRow";
import { MainLayoutPageHeader } from "components/organisms/MainLayout";
import { ErrorWrapper } from "components/organisms/form/BaseField";
import { useGetGroupsQuery } from "pages/Groups/GroupsPage.graphql-gen";
import { InfoColumnValue } from "pages/Users/EditUserPage";

export default function GroupsPage() {
	const navigate = useNavigate();
	const { data, error } = useGetGroupsQuery();

	return (
		<Flex direction={Direction.column} gap="calc( var(--grid-2) * 2)">
			<MainLayoutPageHeader
				title={
					<Flex gap="var(--grid-2)">
						Grupper
						<Link to="create" title="Ny gruppe">
							<FontAwesomeIcon icon={faPlusCircle} />
						</Link>
					</Flex>
				}
				intro="Brukergrupper"
			/>
			<Flex direction={Direction.column} gap="var(--grid-2)">
				{error && <ErrorWrapper>{error.message}</ErrorWrapper>}
				{data?.groups?.map((group) => (
					<InfoRow
						key={group.id}
						interactive
						onClick={() => {
							navigate("/groups/" + group.id);
						}}
					>
						<InfoColumn width="25%">
							<InfoColumnValue>
								<b>{group?.displayName}</b> ({group?.usersCount} {group?.usersCount === 1 ? "bruker" : "brukere"})
							</InfoColumnValue>
						</InfoColumn>
					</InfoRow>
				))}
			</Flex>
		</Flex>
	);
}
