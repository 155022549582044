import { useNavigate } from "react-router-dom";

import { Direction, Flex } from "components/atoms/Flex";
import { InfoRow, InfoColumn, InfoColumnValue } from "components/atoms/InfoRow";
import { ErrorWrapper } from "components/organisms/form/BaseField";

import { useGetGridOwnerMappingsQuery } from "./GridOwnerMappingsPageMappedTab.graphql-gen";

export default function GridOwnerMappingsPageMappedTab() {
	const navigate = useNavigate();
	const { data, error } = useGetGridOwnerMappingsQuery();

	return (
		<Flex direction={Direction.column} gap="var(--grid-2)">
			{error && <ErrorWrapper>{error.message}</ErrorWrapper>}
			{data?.gridOwnerMappings?.map((mapping) => (
				<InfoRow
					key={mapping.id}
					interactive
					onClick={() => {
						navigate("/grid-templates/" + mapping.id);
					}}
				>
					<InfoColumn>
						<InfoColumnValue>
							<b>{mapping?.name}</b> {mapping?.numberOfUsers ? `(${mapping?.numberOfUsers} brukere)` : ""}
						</InfoColumnValue>
					</InfoColumn>
				</InfoRow>
			))}
		</Flex>
	);
}
