import Skeleton from "react-loading-skeleton";

import { Flex, Direction } from "components/atoms/Flex";
import { InfoColumn, InfoRow, InfoColumnValue } from "components/atoms/InfoRow";
import useDateTime from "hooks/useDateTime";

import { useGetUserNotificationsQuery } from "./EditUserPageNotificationsTab.graphql-gen";

export default function EditUserPageNotificationsTab({ userId }: { userId: string }) {
	const { format } = useDateTime();
	const { data, loading } = useGetUserNotificationsQuery({ variables: { userId } });

	return loading ? (
		<Flex direction={Direction.column} gap="var(--grid-2)">
			<InfoRow compact>
				<InfoColumn width="100%">
					<Skeleton />
				</InfoColumn>
			</InfoRow>
		</Flex>
	) : (
		<Flex direction={Direction.column} gap="var(--grid-2)">
			{data?.notifications?.items?.map((notification) => (
				<InfoRow compact key={notification.notificationId}>
					<InfoColumn>
						<InfoColumnValue>{notification?.title}</InfoColumnValue>
					</InfoColumn>
					<InfoColumn>
						<InfoColumnValue style={{ paddingRight: "var(--grid-2)" }}>Innhold: {notification?.content}</InfoColumnValue>
					</InfoColumn>
					<InfoColumn>
						<InfoColumnValue>Sendt: {format(notification?.sentAt)}</InfoColumnValue>
					</InfoColumn>
				</InfoRow>
			))}
			{(!data?.notifications?.items || data?.notifications?.items.length === 0) && "Ingen varsler å vise"}
		</Flex>
	);
}
