import { ReactNode } from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

import { faArrowUpRightFromSquare } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Direction, Flex, Justify } from "components/atoms/Flex";

const Label = styled.span`
	font-size: 1.25rem;
	line-height: 1.15;
	font-weight: 400;
`;

const SubLabel = styled.span`
	font-size: 1rem;
	line-height: 1.2;
	font-weight: normal;
`;

const IconWrapper = styled.span`
	color: var(--nte-blaa);
	font-size: 1.3rem;
	line-height: 1;
	align-self: center;
`;

export const StyledNavLink = styled(NavLink)`
	display: flex;
	align-content: center;
	min-height: 4.6rem;
	box-sizing: border-box;
	text-decoration: none;
	border-right: 6px solid transparent;
	color: var(--nte-graa-lys);
	font-weight: 500;
	position: relative;
	transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1);
	grid-column: 2;
	svg {
		width: 2rem;
		color: var(--nte-graa-lys);
	}

	&.active,
	&:hover,
	&:focus {
		text-decoration: none;
		border-right: 6px solid var(--nte-blaa);
		color: var(--nte-graa-lys);
		span:first-child {
			font-weight: 700;
		}
	}
`;

export const StyledATag = styled.a`
	display: flex;
	align-items: center;
	gap: 0.5rem;
	min-height: 4.6rem;
	box-sizing: border-box;
	text-decoration: none;
	border-right: 6px solid transparent;
	color: var(--nte-graa-lys);
	font-weight: 500;
	position: relative;
	transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1);
	grid-column: 2;
	svg {
		color: var(--nte-graa-lys);
	}

	&.active,
	&:hover,
	&:focus {
		text-decoration: none;
		border-right: 6px solid var(--nte-blaa);
		color: var(--nte-graa-lys);
	}
`;
export interface MenuItemProps {
	label: ReactNode;
	subLabel?: ReactNode;
	to?: string;
	href?: string;
	icon: ReactNode;
	exact?: boolean;
}
export default function MenuItem(props: MenuItemProps) {
	const content = (
		<Flex direction={Direction.row} gap="1rem" alignContent={Justify.center}>
			<Flex direction={Direction.column} justifyContent={Justify.center}>
				<IconWrapper>{props.icon}</IconWrapper>
			</Flex>
			<Flex direction={Direction.column} justifyContent={Justify.center}>
				<Label>{props.label}</Label>
				{props.subLabel && <SubLabel>{props.subLabel}</SubLabel>}
			</Flex>
		</Flex>
	);

	if (props.href) {
		return (
			<StyledATag href={props.href}>
				{content}
				<FontAwesomeIcon icon={faArrowUpRightFromSquare} />
			</StyledATag>
		);
	}

	return <StyledNavLink to={props.to || ""}>{content}</StyledNavLink>;
}
