import { Link, useNavigate } from "react-router-dom";

import { faPlusCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Direction, Flex } from "components/atoms/Flex";
import { InfoRow, InfoColumn } from "components/atoms/InfoRow";
import TrafficLightBadge from "components/molecules/TrafficLightBadge";
import { MainLayoutPageHeader } from "components/organisms/MainLayout";
import { ErrorWrapper } from "components/organisms/form/BaseField";
import useDateTime from "hooks/useDateTime";
import { InfoColumnValue } from "pages/Users/EditUserPage";
import { useServiceMessagesQuery } from './ServiceMessagesPage.graphql-gen';


export default function ServiceMessagesPage() {
    const navigate = useNavigate();
    const date = useDateTime();

    const { data, error } = useServiceMessagesQuery();

    return (
        <div>
            <MainLayoutPageHeader
                title={
                    <Flex gap="var(--grid-2)">
                        Driftsmeldinger
                        <Link to="create" title="Ny driftsmelding">
                            <FontAwesomeIcon icon={faPlusCircle} />
                        </Link>
                    </Flex>
                }
            />
            <Flex style={{ marginTop: "40px" }} direction={Direction.column} gap="var(--grid-2)">
                {error && <ErrorWrapper>{error.message}</ErrorWrapper>}
                {data?.paginateServiceMessages?.items
                    ?.slice()
                    .sort((a, b) => {
                        if (!a || !b || a.publishDate === b.publishDate) return 0;
                        return Date.parse(a.publishDate as string) < Date.parse(b.publishDate as string) ? 1 : -1;
                    })
                    .map((content) => (
                        <InfoRow
                            key={content.id}
                            interactive
                            onClick={() => {
                                navigate("/service-messages/" + content.id);
                            }}
                        >
                            <InfoColumn width="10em">
                                <InfoColumnValue>
                                    <TrafficLightBadge green={content?.published} red={!content?.published}>
                                        <>{content?.published ? "Publisert" : "Ikke publisert"}</>
                                    </TrafficLightBadge>
                                </InfoColumnValue>
                            </InfoColumn>
                            <InfoColumn>
                                <InfoColumnValue>
                                    <b>{content?.title}</b> sist oppdatert {date.format(content?.publishDate)}
                                </InfoColumnValue>
                            </InfoColumn>
                        </InfoRow>
                    ))}
            </Flex>
        </div>
    );
}
