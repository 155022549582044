import { ReactNode, useEffect, useRef, useState } from "react";
import { Link, LinkProps, Outlet } from "react-router-dom";
import styled, { css } from "styled-components";

import {
	faBell,
	faChartPieSimple,
	faFlag,
	faHammerCrash,
	faMagnifyingGlassChart,
	faMap,
	faMobileScreen,
	faNewspaper,
	faPeopleGroup,
	faPeopleRoof,
	faTriangleExclamation,
	faUtilityPoleDouble,
	faCircleP,
	faVials,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Direction, Flex, FlexFullWidth } from "components/atoms/Flex";
import { SecondTitle, Title } from "components/atoms/Typography";
import Footer from "components/molecules/Footer";
import MenuItem from "components/organisms/MenuItem";
import useAccess from "hooks/useAccess";

const LayoutWrapper = styled.div`
	position: relative;
	width: 100%;
	min-height: 100vh;
`;

export const PageWrapperElement = styled(Flex)`
	padding: var(--grid-4);
	width: calc(100% - 300px);
`;

const Sidebar = styled(Flex)`
	min-height: calc(100vh - 100px);
	width: 300px;
	border-right: 1px solid var(--nte-graa-lys);
	background-color: var(--nte-blaa-mork);
	padding: 2rem 0 0 2rem;
	gap: 2rem;
`;

const MenuItems = styled.div<{ topHeight?: number }>`
	diplay: flex;
	flex-direction: column;
	height: max-content;
	position: sticky;
	top: ${(props) => (props?.topHeight ? `${props.topHeight}px` : 0)};
`;

const Logo = styled.img`
	height: 35px;
	margin-right: -11px;
`;

const StyledLink = styled(Link)``;

export default function MainLayout() {
	const access = useAccess();
	const menuRef = useRef<HTMLDivElement>(null);
	const [menuListTopHeight, setMenuListTopHeight] = useState<number>(0);

	useEffect(() => {
		function calculateTopHeight() {
			if (menuRef.current) {
				const windowHeight = window.innerHeight;
				const listHeight = menuRef.current.clientHeight;
				const newCalculatedHeight = windowHeight - listHeight;
				if (newCalculatedHeight < 0 && menuListTopHeight !== newCalculatedHeight) {
					setMenuListTopHeight(newCalculatedHeight);
				} else if (menuListTopHeight !== 0) {
					setMenuListTopHeight(0);
				}
			}
		}
		window.addEventListener("resize", calculateTopHeight);

		calculateTopHeight();

		return () => window.removeEventListener("resize", calculateTopHeight);
	}, []);

	return (
		<LayoutWrapper>
			<FlexFullWidth>
				<Sidebar direction={Direction.column} grow="0" shrink="0">
					<Link to="/" title="Gå til forsiden">
						<Logo src="assets/icons/nte-logo-white.svg" alt="NTE Logo" />
					</Link>
					<MenuItems ref={menuRef} topHeight={menuListTopHeight}>
						<MenuItem to="/users" label="Brukere" icon={<FontAwesomeIcon width="30px" icon={faPeopleGroup} />} />
						{access.includes("Admin") && (
							<>
								<MenuItem to="/groups" label="Grupper" icon={<FontAwesomeIcon width="30px" icon={faPeopleRoof} />} />
								<MenuItem to="/features" label="Features" icon={<FontAwesomeIcon width="30px" icon={faFlag} />} />
								<MenuItem to="/ab-tests" label="A/B Tester" icon={<FontAwesomeIcon width="30px" icon={faVials} />} />
								<MenuItem to="/notification-templates" label="Varsler" icon={<FontAwesomeIcon width="30px" icon={faBell} />} />
								<MenuItem to="/content" label="Innhold" icon={<FontAwesomeIcon width="30px" icon={faNewspaper} />} />
								<MenuItem to="/service-messages" label="Driftsmeldinger" icon={<FontAwesomeIcon width="30px" icon={faTriangleExclamation} />} />
								<MenuItem to="/spotprice-areas" label="Spotprisområder" icon={<FontAwesomeIcon width="30px" icon={faMap} />} />
								<MenuItem to="/stats" label="Statistikk" icon={<FontAwesomeIcon icon={faChartPieSimple} />} />
								<MenuItem to="/fraktaler" label="Fraktaler" icon={<FontAwesomeIcon icon={faMagnifyingGlassChart} />} />
								<MenuItem to="/tools" label="Tools" icon={<FontAwesomeIcon icon={faHammerCrash} />} />
								<MenuItem to="/public/b25b255d" label="Infoskjerm" icon={<FontAwesomeIcon icon={faMobileScreen} />} />
							</>
						)}
						{access.includes("Product") && <MenuItem to="/products" label="Produktadmin" icon={<FontAwesomeIcon icon={faCircleP} />} />}
						<MenuItem to="/grid-templates" label="Netteiere" icon={<FontAwesomeIcon width="30px" icon={faUtilityPoleDouble} />} />
					</MenuItems>
				</Sidebar>

				<PageWrapperElement grow="0" shrink="0" direction={Direction.column}>
					<Outlet />

					<Footer />
				</PageWrapperElement>
			</FlexFullWidth>
		</LayoutWrapper>
	);
}

export const Intro = styled.h2`
	margin-bottom: var(--grid-2);
	text-align: center;
	${(props) =>
		props.theme.media.desktop(css`
			font-size: 20px;
			margin-bottom: 5px;
		`)}
`;

export function MainLayoutPageHeader({
	title,
	intro,
	link,
	loading,
}: {
	title?: ReactNode;
	intro?: ReactNode;
	link?: LinkProps & { label: ReactNode };
	loading?: boolean;
}) {
	return (
		<Flex gap="6px" direction={Direction.column}>
			{title && <Title loading={loading}>{title}</Title>}
			{intro && <SecondTitle loading={loading}>{intro}</SecondTitle>}
			{link && <StyledLink {...link}>{link.label}</StyledLink>}
		</Flex>
	);
}
